import React from "react"
import {Layout} from "../components/layout/layout"

const Missing = () => {
  return (
    <Layout>
      <h1>Page not found</h1>
    </Layout>
  )
}

export const Head = () => (
  <>
    <title>FM Nieuwjaarsborrel 2023</title>
    <meta name="robots" content="none" />
  </>
)

export default Missing
